import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFilteredAds, getFilteredCompanies, getFilteredEvents, getFilteredJobs, getFilteredVideos, getViewCountByModel, getViewsByDateRange } from 'Api/Company';
import { getDynamicDateRanges, getFormatterByFilter } from 'utils/companyUtils';


const defaultStartDate = "2023-01-01";
const defaultEndDate = new Date().toISOString().split("T")[0];

export const fetchTotalViewsCountByModel = createAsyncThunk(
    "dashboard/fetchTotalViewsCountByModel",
    async (modelType, { rejectWithValue }) => {
        try {
            const response = await getViewCountByModel({ modelType });
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.message || "Failed to fetch total views by model");
        }
    });




export const fetchViewsByModel = createAsyncThunk(
    "dashboard/fetchViewsByModel",
    async (modelType, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const dateRange = state.monitoring.analyticsRange;

            const payload = {
                modelType,
                ...dateRange,


            };

            const response = await getViewsByDateRange(payload);

            const formatter = getFormatterByFilter("SingleDate");
            const formattedData = formatter(response.data.results, dateRange, modelType);

            return formattedData;

        } catch (error) {
            return rejectWithValue(error.message || "Failed to fetch views by model");
        }
    }
);

export const fetchViewsByModelAndId = createAsyncThunk(
    "dashboard/fetchViewsByModelAndId",
    async ({ modelType, entityId }, { getState, rejectWithValue }) => {
        try {
            const state = getState();




            const dateRange = state.monitoring.analyticsRange;





            const payload = {
                modelType,
                ...dateRange,
                entityId
            };









            const response = await getViewsByDateRange(payload);


            return response.data || [];
        } catch (error) {

            return rejectWithValue(error.message || "Failed to fetch views by model");
        }
    }
);

export const fetchFilteredData = createAsyncThunk(
    'dashboard/fetchFilteredData',
    async (_, { getState, rejectWithValue }) => {

        const state = getState();
        const { startDate, endDate } = state.monitoring.selectedRange;

        try {
            const [companies, ads, events, jobs, videos] = await Promise.all([

                getFilteredCompanies({ startDate, endDate }),
                getFilteredAds({ startDate, endDate }),
                getFilteredEvents({ startDate, endDate }),
                getFilteredJobs({ startDate, endDate }),
                getFilteredVideos({ startDate, endDate }),
            ]);

            return {
                companies: companies.data?.result?.all?.companies || [],
                ads: ads.data?.all?.companies || [],
                events: events.data?.all?.companies || [],
                jobs: jobs.data?.all?.companies || [],
                videos: videos.data?.all?.companies || [],
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch data');
        }
    }
);

export const fetchCompanies = createAsyncThunk(
    'dashboard/fetchCompanies',
    async ({ startDate, endDate } = {}, { rejectWithValue }) => {
        try {



            const filterStartDate = startDate || defaultStartDate;
            const filterEndDate = endDate || defaultEndDate;

            console.log("Fetching filtered companies with dates:", { filterStartDate, filterEndDate });


            const companies = await getFilteredCompanies({ startDate: filterStartDate, endDate: filterEndDate });

            return companies.data?.result?.all?.companies || [];
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch companies');
        }
    }
);

export const fetchFilteredAds = createAsyncThunk(
    'dashboard/fetchFilteredAds',
    async (_, { rejectWithValue }) => {
        try {
            const startDate = defaultStartDate;
            const endDate = defaultEndDate;
            const ads = await getFilteredAds({ startDate, endDate });
            return ads.data?.all?.companies || [];
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch ads');
        }
    }
);

export const fetchFilteredEvents = createAsyncThunk(
    'dashboard/fetchFilteredEvents',
    async (_, { rejectWithValue }) => {
        try {
            const startDate = defaultStartDate;
            const endDate = defaultEndDate;
            const events = await getFilteredEvents({ startDate, endDate });
            return events.data?.all?.companies || [];
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch events');
        }
    }
);

export const fetchFilteredJobs = createAsyncThunk(
    'dashboard/fetchFilteredJobs',
    async (_, { rejectWithValue }) => {
        try {
            const startDate = defaultStartDate;
            const endDate = defaultEndDate;
            const jobs = await getFilteredJobs({ startDate, endDate });
            return jobs.data?.all?.companies || [];
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch jobs');
        }
    }
);

export const fetchFilteredVideos = createAsyncThunk(
    'dashboard/fetchFilteredVideos',
    async (_, { rejectWithValue }) => {
        try {
            const startDate = defaultStartDate;
            const endDate = defaultEndDate;
            const videos = await getFilteredVideos({ startDate, endDate });
            return videos.data?.all?.companies || [];
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch videos');
        }
    }
);


const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState: {
        companies: [],
        filteredCompanies: [],
        ads: [],
        filteredAds: [],
        events: [],
        filteredEvents: [],
        jobs: [],
        filteredJobs: [],
        videos: [],
        filteredVideos: [],
        loading: false,
        error: null,
        selectedRange: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,


        },
        modelType: "",
        selectedCompanyId: "",
        analyticsRange: getDynamicDateRanges("Month"),
        viewsLoading: false,
        viewsError: null,
        modelAnalytics: {},
        totalViewCount: {},
        entityAnalytics: {},
        selectedEntity: {},
    },
    reducers: {
        setModelType: (state, action) => {
            state.modelType = action.payload;
        },
        setSelectedEntity: (state, action) => {
            console.log(action.payload, "actionssssss");
            state.selectedEntity = action.payload;
        },



        setSelectedCompanyId: (state, action) => {
            state.selectedCompanyId = action.payload;
        },
        setDateRange: (state, action) => {
            const { startDate, endDate } = action.payload;


            state.selectedRange = {
                startDate: new Date(startDate).toISOString(),
                endDate: new Date(endDate).toISOString(),
            };
        },
        setAnalyticsRange: (state, action) => {
            const { startDate, endDate } = action.payload;
            state.analyticsRange = {
                startDate: new Date(startDate).toISOString(),
                endDate: new Date(endDate).toISOString(),
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilteredData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilteredData.fulfilled, (state, action) => {
                const { companies, ads, events, jobs, videos } = action.payload;
                state.companies = companies;
                state.filteredCompanies = companies;
                state.ads = ads;
                state.filteredAds = ads;
                state.events = events;
                state.filteredEvents = events;
                state.jobs = jobs;
                state.filteredJobs = jobs;
                state.videos = videos;
                state.filteredVideos = videos;
                state.loading = false;
            })
            .addCase(fetchFilteredData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchViewsByModel.pending, (state) => {
                state.viewsLoading = true;
                state.viewsError = null;
            })
            .addCase(fetchViewsByModel.fulfilled, (state, action) => {
                state.modelAnalytics = action.payload;
                state.viewsLoading = false;
            })
            .addCase(fetchViewsByModel.rejected, (state, action) => {
                state.viewsLoading = false;
                state.viewsError = action.payload;
            });

        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {

                state.companies = action.payload;
                state.loading = false;
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


        builder
            .addCase(fetchFilteredAds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilteredAds.fulfilled, (state, action) => {

                state.ads = action.payload;
                state.loading = false;
            })
            .addCase(fetchFilteredAds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


        builder
            .addCase(fetchFilteredEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilteredEvents.fulfilled, (state, action) => {

                state.events = action.payload;
                state.loading = false;
            })
            .addCase(fetchFilteredEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


        builder
            .addCase(fetchFilteredJobs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilteredJobs.fulfilled, (state, action) => {

                state.jobs = action.payload;
                state.loading = false;
            })
            .addCase(fetchFilteredJobs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


        builder
            .addCase(fetchFilteredVideos.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilteredVideos.fulfilled, (state, action) => {

                state.videos = action.payload;
                state.loading = false;
            })
            .addCase(fetchFilteredVideos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchTotalViewsCountByModel.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTotalViewsCountByModel.fulfilled, (state, action) => {
                state.totalViewCount = action.payload;

                state.loading = false;
            })
            .addCase(fetchTotalViewsCountByModel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchViewsByModelAndId.pending, (state) => {
                state.viewsLoading = true;
                state.viewsError = null;
            })
            .addCase(fetchViewsByModelAndId.fulfilled, (state, action) => {
                state.entityAnalytics = action.payload;
                state.viewsLoading = false;
            })
            .addCase(fetchViewsByModelAndId.rejected, (state, action) => {
                state.viewsLoading = false;
                state.viewsError = action.payload;
            });

    },
});
export const { setDateRange, setAnalyticsRange, setSelectedCompanyId, setfilterGraphBy, setModelType, setSelectedEntity } = monitoringSlice.actions;
export default monitoringSlice.reducer;


































































