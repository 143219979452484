import Axios from "axios";
import { toast } from "react-toastify";
export const API_URL = Object.freeze({
  LOGIN: "/api/auth/login",
  LOGOUT: "/api/auth/logout",
  VERIFY_TOKEN: "/api/auth/verify-token",
  REGISTER: "/api/auth/register",
  REGISTER_BY_ADMIN: "/api/auth/registerByAdmin",
  FORGET: "/api/auth/forgetPassword",
  SUBSCRIBE: "/api/auth/subscribe",
  CONTACT: "/api/auth/contact",
  GET_ALL_CONTACTS: "/api/contact",
  GET_ALL_NEWSLETTER: "/api/newsletter",
  DELETE_CONTACT: (id) => `/api/contact/${id}`,
  WELCOME: "/api/auth/welcome",
  CONFIRM_RESET: "/api/auth/confirmResetPassword",
  RESET_PASSWORD: "/api/auth/resetPassword",
  GET_COMPANY_BY_ID: (id) => `/api/companies/getCompanyById/${id}`,
  GET_COMPANY_BY_SLUG: (slug) => `/api/slug/${slug}`,
  GET_COMPANY_BY_ID_TO_EDIT: (id) =>
    `/api/companies/getCompanyByIdToEdit/${id}`,
  CHANGE_FAVORITE_COMPANY: "/api/companies/changeFavorite",
  GET_ALL_COMPANIES: "/api/companies/getAllCompanies",
  SEARCH_COMPANIES: "/api/companies/search",
  SEARCH_SUGGESTIONS: "/api/companies/suggestions",
  CREATE_COMPANY: "/api/companies",
  UPDATE_COMPANY: (id) => `/api/companies/${id}`,
  GET_ALL_INDUSTRIES: "/api/industries/",
  GET_ALL_INDUSTRIES_BY_ADMIN: "/api/industries/getAllIndustries",
  GET_ALL_USERS: "/api/users/",
  UPDATE_INDUSTRY: (id) => `/api/industries/${id}`,
  CREATE_INDUSTRY: `/api/industries/`,
  DELETE_INDUSTRY: (id) => `/api/industries/${id}`,
  CREATE_SOCIALS: "/api/socials",
  UPDATE_SOCIALS: (id) => `/api/socials/${id}`,
  CREATE_OFFICE_HOURS: "/api/officeHours",
  UPDATE_OFFICE_HOURS: (id) => `/api/officeHours/${id}`,
  CREATE_VIDEOS: "/api/myVideos",
  DELETE_VIDEO: (id) => `/api/myVideos/${id}`,
  UPLOAD_VIDEO: (id) => `/api/myVideos/uploadVideo/${id}`,
  VIEW_VIDEO: (id) => `/api/myVideos/viewVideos/${id}`,
  UPDATE_VIDEO: (id) => `/api/myVideos/${id}`,
  RECENTLY_ADDED_VIDEO: (id) => `/api/allVideos/recentlyAdded/${id}`,
  MOSTLY_VIEWED_VIDEO: (id) => `/api/allVideos/mostViewCount/${id}`,

  VIEW_COMPANY: (id) => `/api/companies/viewCompany/${id}`,
  VIEW_EVENT: (id) => `/api/event/viewEvent/${id}`,
  VIEW_JOB: (id) => `/api/job/viewJob/${id}`,
  CREATE_PRICE: "/api/price",
  GET_ALL_EVENTS: "/api/Event/getAllEvents",
  CREATE_EVENT: "/api/Event/",
  UPDATE_EVENT: (id) => `/api/Event/${id}`,
  GET_EVENT_BY_ID: (id) => `/api/Event/${id}`,
  DELETE_EVENT: (id) => `/api/Event/${id}`,
  UPDATE_PRICE: (id) => `/api/price/${id}`,
  GET_ALL_VIDEOS: "/api/myVideos/getAllVideos",
  UPDATE_VIDEO_INDEXES: (companyId) =>
    `/api/myVideos/updateVideoIndexes/${companyId}`,
  CREATE_JOB: "/api/job",
  GET_ALL_JOBS: "/api/job/getAllJobs",
  SEARCH_JOBS: "/api/job/searchJobs",
  UPDATE_JOB: (id) => `/api/job/${id}`,
  DELETE_JOB: (id) => `/api/job/${id}`,
  GET_JOB_BY_ID: (id) => `/api/job/${id}`,
  CREATE_JOB_APPLICATION: "/api/jobApplication",
  CREATE_PARTNER_REQUEST: "/api/partnerApproval",
  UPDATE_PARTNER_REQUEST: (id) => `/api/partnerApproval/${id}`,
  GET_ALL_NOTIFICATIONS: "/api/notification",
  SEEN_ALL_NOTIFICATIONS: "/api/notification/seenAllNotifications",
  CREATE_RECOMMENDATION: "/api/recommendation",
  GET_ALL_RECOMMENDATION: "/api/recommendation/getAllRecommendations",
  UPDATE_RECOMMENDATION: (id) => `/api/recommendation/${id}`,
  DELETE_RECOMMENDATION: (id) => `/api/recommendation/${id}`,
  CREATE_AD: "/api/ad",
  UPDATE_AD: (id) => `/api/ad/${id}`,
  GET_ALL_ADS: "/api/ad/getAllAds",
  GET_RANDOM_ADS: "/api/ad/getRandomAd",
  GET_RANDOM_ADS_VERTICAL: "/api/ad/getRandomAdVertical",
  GET_USERS_BY_COMPANY: (companyId) =>
    `/api/users/getUsersByCompany/${companyId}`,
  UPDATE_USER: (id) => `/api/users/${id}`,
  GET_USER_BY_ID: (id) => `/api/users/${id}`,
  BOOKING: "/api/customer",
  GET_ALL_BOOKING: "/api/customer/",
  GET_BOOKING_BY_ID: (id) => `/api/customer/${id}`,
  DELETE_BOOKING: (id) => `/api/customer/${id}`,
  CHECK_USER: "/api/customer/checkUser",
  CREATE_BADGE: "/api/badges",
  GET_ALL_BADGES: "/api/badges",
  DELETE_BADGE: (id) => `/api/badges/${id}`,
  UPDATE_BADGE: (id) => `/api/badges/${id}`,
  CREATE_SUBSCRIPTION: "/api/subscription",
  GET_ALL_SUBSCRIPTIONS: "/api/subscription",
  UPDATE_SUBSCRIPTON: (id) => `/api/subscription/${id}`,
  DELETE_SUBSCRIPTION: (id) => `/api/subscription/${id}`,
  GET_ALL_PERMISSIONS: "/api/permissions",
  DELETE_PERMISSION: (id) => `/api/permissions/${id}`,
  UPDATE_PERMISSION: (id) => `/api/permissions/${id}`,
  CREATE_ROLE: "/api/role",
  GET_ALL_ROLES: "/api/role",
  DELETE_ROLE: (id) => `/api/role/${id}`,
  UPDATE_ROLE: (id) => `/api/role/${id}`,
  GET_ROLE_BY_ID: (id) => `/api/role/${id}`,
  GET_INVOICE: "/api/invoiceEmail",
  COMPANY_FILTER: "/api/companies/filter",
  CREATE_REEL: "/api/reels",
  GE_ALL_REELS: "/api/reels",
  VIEW_REEL: (slug) => `/api/reels/view/${slug}`,
  CREATE_QR_CODE: "/api/qrCode",
  GET_ALL_USER_QR_CODES: (id) => `/api/qrCode/company/${id}`,
  DELETE_QR_CODE: (slug) => `/api/qrCode/${slug}`,
  GET_ALL_ADMIN_QR_CODES: "/api/qrCode/admin",
  GET_VIEWCOUNT_BY_MODEL: "/api/views/totalViewCounts",
  GET_VIEWS_BY_DATE_RANGE: "/api/views/date-range",
  GET_FILTERED_COMPANIES: "/api/companies/filter",
  GET_FILTERED_ADS: "/api/filter/ads",
  GET_FILTERED_EVENTS: "/api/filter/events",
  GET_FILTERED_JOBS: "/api/filter/jobs",
  GET_FILTERED_VIDEOS: "/api/filter/videos",
  VIEW_SOCIAL: (id) => `/api/socials/view/${id}`,
  GENERATE_ABOUT: "/api/openai/generate",
  GENERATE_TEXT: "/api/openaiText/generateText",
  
});

const axiosInstance = () => {
  return Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || `http://localhost:4000`,
    responseType: "json",
    withCredentials: true,
  });
};

const axiosInstanceHeader = () => {
  const accessToken = localStorage.getItem("accessToken");
  return Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || `http://localhost:4000`,
    responseType: "json",
    withCredentials: true,
    headers: {
      Authorization: accessToken,
    },
  });
};

const domainsAxiosInstances = {
  user: axiosInstance(),
  withHeaders: axiosInstanceHeader(),
};

const { user, withHeaders } = domainsAxiosInstances;
const ErrorResponse = async (error) => {
  try {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.message === "Invalid token"
    ) {
      const data = await getAccessToken();
      const request = {
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: data?.accessToken,
        },
      };
      return withHeaders.request(request);
    } else if (
      error?.response?.status === 400 &&
      error?.response?.data?.message === "Unauthorized"
    ) {
      toast.error("Access Denied", { autoClose: 2000 });
    }

    // else if (
    //   error?.response?.status === 404 &&
    //   error?.response?.data === "Company Not Found."
    // ) {
    //   window.location.href = `${window.location.origin}${"/"}`;
    // } else if (error?.response?.status === 404) {
    //   window.location.href = `${window.location.origin}${"/fehlerseite404"}`;
    // }
  } catch (e) {
    window.location.href = `${window.location.origin}${"/login"}`;
  }
  return Promise.reject(error);
};
const getAccessToken = async () => {
  try {
    let { data } = await user.post("/api/auth/refresh-token", {
      refreshToken: localStorage.getItem("refreshToken"),
    });
    if (data?.accessToken) {
      const { accessToken } = data;
      localStorage.setItem("accessToken", accessToken);
      return data;
    }
  } catch (e) {
    window.location.href = `${window.location.origin}${"/login"}`;
  }
};

const domainResponse = async (response) => {
  if (response?.config?.url === "/api/auth/login" && response?.status === 200) {
    localStorage.setItem("accessToken", response?.data?.accessToken);
    localStorage.setItem("refreshToken", response?.data?.refreshToken);
    localStorage.setItem("user", JSON.stringify(response?.data?.user));
    if (response?.data?.user?.companies?.length > 1) {
      window.location.href = `${window.location.origin
        }${`/unternehmen/stammdaten/${response?.data?.user?.companies[0]}`}`;
    } else {
      window.location.href = `${window.location.origin}${`/onboarding`}`;
    }
  }
  return response;
};

const interceptorsResponse = (
  domainInstance,
  _domainResponse,
  _ErrorResponse
) => {
  domainInstance.interceptors.response.use(
    (response) => _domainResponse(response),
    (error) => _ErrorResponse(error)
  );
};
interceptorsResponse(withHeaders, domainResponse, ErrorResponse);
interceptorsResponse(user, domainResponse, ErrorResponse);
export default domainsAxiosInstances;
