import { getCompanyByIdToEdit } from "Api/Company";
import { formatCompanyData } from "lib/helper";
import { setUpdateAllCompanies, setUserCurrentCompany } from "../redux/slices/companiesSlice";

export const fetchAndUpdateCompany = async (
  id,
  dispatch,
  history
) => {
  try {
    const { data } = await getCompanyByIdToEdit(id);
    if (data) {

      let formattedCompanyData = formatCompanyData(data);
      dispatch(setUserCurrentCompany(formattedCompanyData));
      dispatch(setUpdateAllCompanies(data))
      return formattedCompanyData;
    } else {
      history.push("/fehlerseite404");
      return;
    }
  } catch (error) {
    console.error("Error fetching or updating company data:", error);
  }
};

export const getDynamicDateRanges = (activeFilter) => {
  const currentDate = new Date();

  const startDate = new Date(currentDate);

  if (activeFilter === "Day") {
    startDate.setDate(currentDate.getDate() - 1); // Previous day
  } else if (activeFilter === "Week") {
    startDate.setDate(currentDate.getDate() - 7); // 7 days ago
  } else if (activeFilter === "Month") {
    startDate.setMonth(currentDate.getMonth() - 1); // 1 month ago
  } else if (activeFilter === "Year") {
    startDate.setFullYear(currentDate.getFullYear() - 1); // 1 year ago
  }

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(currentDate),
  };
};

export const formatDate = (date) => {
  const offset = date.getTimezoneOffset(); // Get timezone offset in minutes
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().split("T")[0];
};

export const filterByTime = [
  { key: "Day", value: "Tag" },
  { key: "Week", value: "Woche" },
  { key: "Month", value: "Monat" },
  { key: "Year", value: "Jahr" }
];

export const getFormatterByFilter = (filter) => {
  const formatters = {
    Month: formatGraphDataForMonth,
    Year: formatGraphDataForYear,
    Day: formatGraphDataForDay,
    Week: formatGraphDataForWeek,
    Date: formatGraphDataForCustomDateRange,
    SingleDate: formatGraphDataForCustomDateRangeForModel,
  };
  return formatters[filter];
};
export const formatGraphDataForCustomDateRange = (rawData, selectedRange) => {
  const startDate = new Date(selectedRange.startDate);
  const endDate = new Date(selectedRange.endDate);
  const dateMap = {};
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const formattedDate = currentDate.toISOString().split("T")[0];
    dateMap[formattedDate] = {
      name: formattedDate,
      Company: 0,
      Video: 0,
      Ad: 0,
      Job: 0,
      Event: 0,
    };
    currentDate.setDate(currentDate.getDate() + 1);
  }
  rawData.forEach(({ modelType, data }) => {
    data.forEach((entity) => {
      entity.viewsByDate.forEach(({ date, totalViews }) => {
        const formattedDate = new Date(date).toISOString().split("T")[0];
        if (dateMap[formattedDate]) {
          dateMap[formattedDate][modelType] += totalViews;
        }
      });
    });
  });
  return Object.values(dateMap);
};
export const formatGraphDataForCustomDateRangeForModel = (rawData, selectedRange, modelType) => {
  const startDate = new Date(selectedRange.startDate);
  const endDate = new Date(selectedRange.endDate);

  // Initialize the date map
  const dateMap = {};
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const formattedDate = currentDate.toISOString().split("T")[0];
    dateMap[formattedDate] = {
      name: formattedDate,
      [modelType]: 0, // Initialize the specific modelType
    };
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Process rawData for the specified modelType
  rawData.forEach((entity) => {
    entity.viewsByDate.forEach(({ date, totalViews }) => {
      const formattedDate = new Date(date).toISOString().split("T")[0];
      if (dateMap[formattedDate]) {
        dateMap[formattedDate][modelType] += totalViews; // Increment the totalViews for the specific modelType
      }
    });
  });

  return Object.values(dateMap); // Convert the date map to an array
};
export const formatGraphDataForMonth = (rawData, _date) =>

  formatGraphDataByBuckets(rawData, "week", ["Woche 1", "Woche 2", "Woche 3", "Woche 4"]);

export const formatGraphDataForWeek = (rawData) =>
  formatGraphDataByBuckets(rawData, "weekday", ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]);
export const formatGraphDataForDay = (rawData) =>
  formatGraphDataByBuckets(
    rawData,
    "hour",
    Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, "0")}:00`)
  );
export const formatGraphDataForYear = (rawData) =>
  formatGraphDataByBuckets(rawData, "month", [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
  ]);
export const formatGraphDataByBuckets = (rawData, bucketType, bucketLabels) => {
  const buckets = bucketLabels.map((label) => ({
    name: label,
    Company: 0,
    Video: 0,
    Ad: 0,
    Job: 0,
    Event: 0,
  }));
  rawData.forEach(({ modelType, data }) => {
    data.forEach((entity) => {
      entity.viewsByDate.forEach(({ date, totalViews }) => {
        const bucketIndex = getBucketIndex(new Date(date), bucketType);
        if (bucketIndex !== null && buckets[bucketIndex]) {
          buckets[bucketIndex][modelType] += totalViews;
        }
      });
    });
  });
  return buckets;
};

export const formatModelGraphDataByBuckets = (rawData, bucketType, bucketLabels, modelType) => {
  // Initialize buckets with dynamic modelType as key
  const buckets = bucketLabels.map((label) => ({
    name: label,
    [modelType]: 0, // Use modelType dynamically
  }));

  // Process rawData for the specific modelType
  rawData.forEach((entity) => {
    entity.viewsByDate.forEach(({ date, totalViews }) => {
      const bucketIndex = getBucketIndex(new Date(date), bucketType);
      if (bucketIndex !== null && buckets[bucketIndex]) {
        buckets[bucketIndex][modelType] += totalViews; // Accumulate totalViews for the given modelType
      }
    });
  });

  return buckets;
};

export const getBucketIndex = (date, bucketType) => {
  switch (bucketType) {
    case "hour":
      return date.getHours();
    case "weekday":
      return date.getDay();
    case "month":
      return date.getMonth();
    case "week":
      return Math.ceil(date.getDate() / 7) - 1;
    default:
      return null;
  }
};


export const transformGroupedDataForBarGraph = (groupedData) => {
  return groupedData.map((group) => {
    const transformedGroup = { name: group.category };
    group.data.forEach((item) => {
      transformedGroup[item.name] = item.Ads;
    });
    return transformedGroup;
  });
};


export const calculateGrowth = (currentValue, previousValue, decimalPlaces = 2) => {
  if (previousValue > 0) {
    const growth = ((currentValue - previousValue) / previousValue) * 100;
    return growth.toFixed(decimalPlaces);
  }
  return "0.00"; // Default value for 0 growth
};



export const calculateTotalViews = (viewsByDate) => viewsByDate?.reduce((sum, item) => sum + item.totalViews, 0);
export const getCompanyName = (companyId, companies) => {
  const company = companies.find((company) => company._id === companyId);
  return company ? company.name : "Unknown";
};

export const getVideoLinkById = (id, videos) => {
  const video = videos.find((video) => video._id === id); // Find video by ID
  return video
    ? {
      video: video.link,
      thumbnail: video.thumbnail,
      title: video.title,
    }
    : null; // Return the link and thumbnail if video exists, otherwise return null
};