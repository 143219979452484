import { createSlice } from "@reduxjs/toolkit";

const qrCodeSlice = createSlice({
  name: "qrcodes",
  initialState: {
    qrCodesByAdmin: [],
    qrCodesByUser: [],
  },
  reducers: {
    setQrCodesByAdmin: (state, action) => {
      state.qrCodesByAdmin = action.payload;
    },
    deleteQrCodeByAdmin: (state, action) => {
      state.qrCodesByAdmin = state.qrCodesByAdmin.filter(
        (qrCode) => qrCode.slug !== action.payload
      );
    },
    addQrCodeByAdmin: (state, action) => {
      state.qrCodesByAdmin.push(action.payload); // Append the new QR code
    },
    setQrCodesByUser: (state, action) => {
      state.qrCodesByUser = action.payload;
    },
    deleteQrCodeByUser: (state, action) => {
      state.qrCodesByUser = state.qrCodesByUser.filter(
        (qrCode) => qrCode.slug !== action.payload
      );
    },
    addQrCodeByUser: (state, action) => {
      state.qrCodesByUser.push(action.payload); // Append the new QR code
    },
  },
});

export const {
  setQrCodesByAdmin,
  deleteQrCodeByAdmin,
  addQrCodeByAdmin,
  setQrCodesByUser,
  deleteQrCodeByUser,
  addQrCodeByUser,
} = qrCodeSlice.actions;

export default qrCodeSlice.reducer;
