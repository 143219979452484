import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import "./index.scss";
import CookieConsentModal from "components/CookieConsent";
import { Provider } from "react-redux";
import store from "./redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <Router>
        <CookiesProvider>
          <CookieConsentModal />
          <App />
        </CookiesProvider>
      </Router>
    </Provider>
  // </React.StrictMode>
);
