import { configureStore } from '@reduxjs/toolkit';
import companiesReducer from './slices/companiesSlice';
import jobsReducer from './slices/jobsSlice';
import eventsReducer from './slices/eventsSlice';
import qrCodesReducer from './slices/qrCodeSlice';
import monitoringReducer from './slices/monitoringSlice';

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    jobs: jobsReducer,
    events: eventsReducer,
    qrcodes: qrCodesReducer,
    monitoring: monitoringReducer,
  },
});

export default store;
