export const formatCompanyData = (data) => {
  let formattedData = {
    id: data?._id || "",
    master: {
      businessName: data?.name || "",
      companyBanner: {
        src: data?.banner || "",
        file: {},
      },
      companyLogo: data?.logo
        ? {
            src: data?.logo || "",
            file: {},
          }
        : null,
      companyTempBanner: {
        src: data?.banner || "",
        file: {},
      },
      companyTempLogo: data?.logo
        ? {
            src: data?.logo || "",
            file: {},
          }
        : null,
      businessCategory: data?.industry[0]?._id || "",
      businessCategoryName: data?.industry[0]?.name || "",
      companyEmail: data?.email || "",
      companyMobile: data?.phone || "",
      companySecondMobile: data?.phone2 || "",
      companyWebsite: data?.website || "",
      companyAddress: {
        country: data?.customAddress2?.country || "",
        city: data?.customAddress2?.city || "",
        postalCode: data?.customAddress2?.plz || "",
        formattedAddress: data?.customAddress2?.formattedAddress || "",
        latitude: data?.customAddress2?.latitude || "",
        longitude: data?.customAddress2?.longitude || "",
      },
      addressLine1: {
        country: data?.address?.country || "",
        city: data?.address?.city || "",
        postalCode: data?.address?.plz || "",
        formattedAddress: data?.address?.formattedAddress || "",
        latitude: data?.address?.latitude || "",
        longitude: data?.address?.longitude || "",
      },
      city: data?.city || "",
      plz: data?.plz || "",
      country: data?.country || "",
    },
    socials:
      data?.social?.social?.map((singleSocial) => {
        return {
          key: singleSocial?.type?.toLowerCase() || "",
          value: singleSocial?.url || "",
        };
      }) || [],
    openingHours: {
      monday: data?.officeHours?.monday?.enabled
        ? data?.officeHours?.monday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      tuesday: data?.officeHours?.tuesday?.enabled
        ? data?.officeHours?.tuesday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      wednesday: data?.officeHours?.wednesday?.enabled
        ? data?.officeHours?.wednesday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      thursday: data?.officeHours?.thursday?.enabled
        ? data?.officeHours?.thursday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      friday: data?.officeHours?.friday?.enabled
        ? data?.officeHours?.friday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      saturday: data?.officeHours?.saturday?.enabled
        ? data?.officeHours?.saturday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
      sunday: data?.officeHours?.sunday?.enabled
        ? data?.officeHours?.sunday?.hours?.map((hour) => {
            return {
              start: {
                hour: hour?.startHour?.split(":")[0],
                minute: hour?.startHour?.split(":")[1],
              },
              end: {
                hour: hour?.endHour?.split(":")[0],
                minute: hour?.endHour?.split(":")[1],
              },
            };
          })
        : [],
    },
    socialId: data?.social?._id || "",
    officeHours: data?.officeHours,
    officeHoursId: data?.officeHours?._id || "",
    videos:
      data?.myVideos?.map((video) => {
        return {
          id: video?._id,
          index: video?.lineIndex,
          src: video?.link,
          thumbnail: video?.thumbnail,
          compressedThumbnail: video?.compressedThumbnail,
          compressVideoLink: video?.compressVideoLink,
          title: video?.title,
          description: video?.description || "",
          viewCount: video?.viewCount || 0,
          isEnabled: video?.isEnabled,
          isAd: video?.isAd || false,
          isReel: video?.isReel || false,
          status: video?.status || "",
          tags: video?.tags || [],
          companyId: video?.company,
          videoThumbnailBanner: video?.videoThumbnailBanner || "",
          updatedAt: video?.updatedAt || "",
          createdAt: video?.createdAt || "",
          key: video?.key || "",
          mimetype: video?.mimetype || "",
          vimeoLink: video?.vimeoLink || "",
        };
      }) || [],
    slug: data?.slug || "",
    services:
      data?.services?.map((service) => {
        return service?.name;
      }) || [],
    tagline: data?.tagLine || "",
    about: data?.about || "",
    menu: data?.menu
      ? {
          src: data?.menu?.link || "",
          type: data?.menu?.type || "",
        }
      : null,
    pricelist: data?.priceList
      ? {
          src: data?.priceList?.link,
          type: data?.priceList?.type,
        }
      : null,
    jobs: data?.jobs || [],
    events: data?.events || [],
    partners: data?.partners || [],
    isNewsletter: data?.isNewsletter,
    favorite: data?.favorite,
    tier: data?.tier,
    isApproved: data?.isApproved,
  };
  data?.price?.forEach((singlePrice) => {
    if (singlePrice?.priceType === "menu") {
      formattedData.menu = {
        name: "",
        type: singlePrice?.type,
        size: 0,
        url: singlePrice?.link,
      };
    } else {
      formattedData.pricelist = {
        name: "",
        type: singlePrice?.type,
        size: 0,
        url: singlePrice?.link,
      };
    }
  });
  return formattedData;
};

export const formatMasterData = (data) => {
  const { master, tagline, about } = data;
  const userData = JSON.parse(localStorage?.getItem("user") || {});
  return {
    user: userData?._id,
    name: master?.businessName,
    banner: master?.companyBanner?.src || "",
    logo: master?.companyLogo?.src || "",
    email: master?.companyEmail,
    phone: master?.companyMobile,
    phone2: master?.companySecondMobile,
    website: master?.companyWebsite,
    address: {
      formattedAddress: master?.addressLine1?.formattedAddress,
      city: master?.addressLine1?.city,
      plz: master?.addressLine1?.postalCode,
      country: master?.addressLine1?.country,
      latitude: master?.addressLine1?.latitude,
      longitude: master?.addressLine1?.longitude,
    },
    customAddress2: {
      formattedAddress: master?.companyAddress?.formattedAddress,
      city: master?.companyAddress?.city,
      plz: master?.companyAddress?.postalCode,
      country: master?.companyAddress?.country,
      latitude: master?.companyAddress?.latitude,
      longitude: master?.companyAddress?.longitude,
    },
    plz: master?.plz,
    city: master?.city,
    country: master?.country,
    viewCount: 0,
    isApproved: data?.isApproved || false,
    tagLine: tagline,
    about: about,

    industry: [master?.businessCategory],
  };
};

export const formatOpeneingHours = (openingHours, company) => {
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  let formattedOpeningHours = {};
  days?.forEach((singleDay) => {
    formattedOpeningHours[singleDay] = {
      enabled:
        openingHours[singleDay] && openingHours[singleDay]?.length
          ? true
          : false,
      hours: openingHours[singleDay]
        ? openingHours[singleDay]?.map((slot) => {
            return {
              startHour: `${slot?.start?.hour}:${slot?.start?.minute}`,
              endHour: `${slot?.end?.hour}:${slot?.end?.minute}`,
            };
          })
        : [],
    };
  });
  if (company) {
    formattedOpeningHours.company = company;
  }
  return formattedOpeningHours;
};

export const formatMyVideos = (videos, ownerType, owner = "") => {
  return {
    owner: owner || JSON.parse(localStorage.getItem("user"))?.companies[0],
    ownerType,
    myVideos: videos?.map((video) => {
      return {
        videoId: video?.videoId || "",
        title: video?.title,
        link: video?.src,
        thumbnail: `${video?.src}-thumbnail`,
        largeImageLink: `${video?.src}-largeImageLink`,
        vimeoLink: `${video?.src}-vimeoLink`,
        vimeoId: "123456789",
        compressVideoLink: `${video?.src}-compressVideoLink`,
        lineIndex: video?.index,
        viewCount: 0,
        isEnabled: true,
      };
    }),
  };
};

const EARTH_RADIUS_KM = 6371; // Earth radius in kilometers

function haversine(lat1, lon1, lat2, lon2) {
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = EARTH_RADIUS_KM * c;
  return distance;
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export const filterCoordinates = (
  referenceLat,
  referenceLon,
  distance,
  companies
) => {
  const filteredCoordinates = companies.filter((company) => {
    const { latitude, longitude } = company?.address;
    const dist = haversine(referenceLat, referenceLon, latitude, longitude);
    return dist <= distance;
  });

  return filteredCoordinates;
};
export const isCurrentTimeBetween = (hoursData) => {
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  console.log("hoursData", hoursData);
  console.log(
    `Current time: ${currentHour}:${currentMinute} (${currentTimeInMinutes} minutes)`
  );

  for (const hourRange of hoursData) {
    const startHour = parseInt(hourRange.startHour.split(":")[0], 10);
    const startMinute = parseInt(hourRange.startHour.split(":")[1], 10);
    let endHour = parseInt(hourRange.endHour.split(":")[0], 10);
    const endMinute = parseInt(hourRange.endHour.split(":")[1], 10);

    // Convert 12-hour format to 24-hour format if necessary
    if (endHour < startHour) {
      endHour += 12;
    }

    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;

    console.log(
      `Checking range: ${startHour}:${startMinute} - ${endHour}:${endMinute}`
    );
    console.log(
      `Start time in minutes: ${startTimeInMinutes}, End time in minutes: ${endTimeInMinutes}`
    );

    if (
      currentTimeInMinutes >= startTimeInMinutes &&
      currentTimeInMinutes <= endTimeInMinutes
    ) {
      console.log("Current time is within range.");
      return true;
    }
  }

  console.log("Current time is not within any range.");
  return false;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the Earth in kilometers
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var distance = R * c; // Distance in kilometers

  return distance.toFixed(2);
};
