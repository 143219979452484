import React from "react";
import instagram from "assets/images/instagram.png";
import facebook from "assets/images/facebook.png";
import LinkedIn from "assets/images/LinkedIn.png";
import LogoSvg from "assets/svgs/logoSvg";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const { currentUserData, userLoggedIn } = useSelector(
    (state) => state.companies
  );
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="main-footer">
        <div className="section-one col">
          <div className="logo">
            <LogoSvg />
          </div>
          <div className="description">
            Cheggl ist das innovative Video-Branchenbuch am internationalen
            Markt. Das richtige Unternehmen finden in einem Meer an
            Möglichkeiten? Mit Cheggl findest Du genau das Unternehmen, das zu
            dir passt. Oder eben die passenden Kunden. Wir lieben Videos! CHEGGL
            #videosfüralle
          </div>
          <div className="follow">
            <div className="footerHeading">FOLGE UNS AUF</div>
            <div className="socialShare">
              <span>
                <a
                  href="https://www.facebook.com/pages/category/Video-Creator/Cheggl-GmbH-108536584202586/"
                  target={"_blank"}
                >
                  <img src={facebook} alt="facebook" />
                </a>
              </span>
              <span>
                <a
                  href="https://de.linkedin.com/company/cheggl-gmbh"
                  target={"_blank"}
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </span>
              <span>
                <a
                  href="https://www.instagram.com/cheggl_videoagentur/"
                  target={"_blank"}
                >
                  <img src={instagram} alt="instagram" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="section-two col">
          <div className="heading">SERVICE</div>
          <div className="footer-menu">
            {!userLoggedIn && (
              <>
                <Link to="/login">Login</Link>
                <Link to="/registration">Registrieren</Link>
              </>
            )}

            <a href="https://cheggl.info/marketing">Video bestellen</a>
            {/* <a href="#">Werben auf Cheggl</a> */}
            <Link to="/newsletter">Newsletter</Link>
            <Link to="/kontakt">Kontakt</Link>
            <a href="https://cheggl.info/job-openings">Jobs bei Cheggl</a>
          </div>
        </div>
        <div className="section-three col">
          <div className="heading">Rechtliches</div>
          <div className="footer-menu">
            <Link to="/datenschutzerklaerung">Datenschutz</Link>
            {/* <Link to="/widerrufsbelehrung">Widerrufsbelehrung</Link> */}
            <Link to="/agb">AGB </Link>
            <Link to="/impressum">Impressum</Link>
          </div>
        </div>
        {/* <div className="section-four col">
          <div className="heading">NEWSLETTER</div>
          <div className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
            elit nulla augue morbi. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Nunc vitae elit nulla augue morbi.
          </div>
          <hr />
          <div className="importantLinks">
            <a href="#">HOME</a>
            <span className="dot"></span>
            <a href="#">BLOG</a>
            <span className="dot"></span>
            <a href="#">LISTING</a>
            <span className="dot"></span>
            <a href="#">CONTACTS</a>
          </div>
        </div> */}
      </div>
      <hr />
      <div className="copyrights">© 2020-{currentYear} All Rights Reserved.</div>
    </>
  );
};

export default Footer;
